import { SOURCE_LOCATION } from "~/constants";

export default function useSourceLocation() {
  const route = useRoute();

  const sourceLocation = computed(() => {
    if (route.path.includes(SOURCE_LOCATION.TEAM)) {
      return SOURCE_LOCATION.TEAM;
    }

    if (route.path.includes(SOURCE_LOCATION.LEAGUE)) {
      return SOURCE_LOCATION.LEAGUE;
    }

    if (route.path.includes("sub-category")) {
      return SOURCE_LOCATION.SUB_CATEGORY;
    }

    if (route.path.includes(SOURCE_LOCATION.CATEGORY)) {
      return SOURCE_LOCATION.CATEGORY;
    }

    return SOURCE_LOCATION.HOME;
  });

  return sourceLocation;
}
